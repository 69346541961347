import * as React from "react";
import SEO from "../../components/seo";

import { StaticImage } from "gatsby-plugin-image";
import ContactUs from "../../components/ContactUs";
import MoreProjects from "../../components/MoreProjects";
import { useEffect, useState } from "react";

const CrowdhausPage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
    <div className="overflow-x-hidden">
      {/* <div className="max-w-full"> */}
      <SEO title="Crowdhaus - Spring" lang="en" />
      <div className="text-black100 font-inter" id="top">
        <main className="max-w-7xl px-6 lg:px-8 mx-auto relative ">
          <div className="">
            <div className="hidden lg:block w-full mt-9 mb-20">
              <StaticImage
                draggable="false"
                className="w-full h-full"
                src="../../images/projects/crowdhaus/header.png"
                alt="Crowdhaus App"
                placeholder="blurred"
              />
            </div>
            <div className="w-full font-inter mt-9 mb-20 md:my-20 text-left xl:mx-32">
              <h1 className="text-4xl md:text-4xl lg:text-5xl font-semibold ">
                Crowdhaus is a next-level property
                <br className="hidden lg:block" /> discovery platform{" "}
                <span className="text-crowdhausPink">
                  designed to stand out <br className="hidden lg:block" /> from
                  the crowd.
                </span>
              </h1>
              <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:space-x-52 md:mt-8 mt-6">
                <p className="md:w-1/2 max-w-lg md:text-base">
                  A modern take on property discovery; Crowdhaus steps up
                  against industry giants with a fresh, minimal approach. Clean
                  lines, elegant design and innovate features, Crowdhaus was
                  redesigned from the ground up, giving the edge over existing
                  offerings with a simple yet powerful suite of features,
                  including Groups, with a roadmap to include Guides & third
                  party integrations.
                </p>
                <p className="md:text-base ">
                  - UI Design <br />- UX Design <br />- Visual Identity <br />-
                  iOS Development
                </p>
              </div>
            </div>
          </div>

          {/* IMAGES */}
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/1o1.png"
                alt="Typography"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/1o2.png"
                alt="Explore Properties"
                placeholder="blurred"
              />
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/crowdhaus/2.png"
              alt="Property Details"
              placeholder="blurred"
            />
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/3o1.png"
                alt="Group Conversation"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/3o2.png"
                alt="Groups Features"
                placeholder="blurred"
              />
            </div>
            {/* To unhide chage 'hidden' to 'flex' */}
            <div className=" flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0 xl:mx-32 md:py-20 hidden">
              <p className="md:w-1/2  md:text-base">
                A modern take on property discovery; Crowdhaus steps up against
                industry giants with a fresh, minimal approach. Clean lines,
                elegant design and innovate features, Crowdhaus was redesigned
                from the ground up, giving the edge over existing offerings with
                a simple yet powerful suite of features, including Groups, with
                a roadmap to include Guides & third party integrations.
              </p>
              <p className="md:w-1/2  md:text-base">
                A modern take on property discovery; Crowdhaus steps up against
                industry giants with a fresh, minimal approach. Clean lines,
                elegant design and innovate features, Crowdhaus was redesigned
                from the ground up, giving the edge over existing offerings with
                a simple yet powerful suite of features, including Groups, with
                a roadmap to include Guides & third party integrations.
              </p>
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/crowdhaus/4.png"
              alt="Properties on Map"
              placeholder="blurred"
            />
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/5o1.png"
                alt="Lendobox"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/5o2.png"
                alt="Darkmode Groups"
                placeholder="blurred"
              />
            </div>

            <div className="w-full text-base md:py-16 flex justify-center items-center">
              <p className="text-left max-w-[31rem] ">
                As part of the promotion for Crowdhaus, we designed a
                promotional newspaper which we took on a photoshoot throughout
                London. The art direction of the shoot was to highlight the bright
                colour of the brand, and so the final edits were black and
                white, aside from the pop of colour from the newspaper itself.
              </p>
            </div>
            <StaticImage
              draggable="false"
              className="w-full"
              src="../../images/projects/crowdhaus/6.png"
              alt="Propertea Daily open on underground"
              placeholder="blurred"
            />
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/7o1.png"
                alt="Propertea Daily open on staircase"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/7o2.png"
                alt="Propertea Daily open outside"
                placeholder="blurred"
              />
            </div>
            <div className="flex flex-col space-y-8 md:flex-row md:space-x-8 md:space-y-0">
              <StaticImage
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/8o1.png"
                alt="Propertea Daily open in cafe"
                placeholder="blurred"
              />
              <StaticImage
                draggable="false"
                className="md:w-1/2"
                src="../../images/projects/crowdhaus/8o2.png"
                alt="Propertea Daily open outside office"
                placeholder="blurred"
              />
            </div>
          </div>
          <MoreProjects excludeProject="crowdhaus" />
          <ContactUs />
        </main>
      </div>
    </div>
    // </div>
  );
  }
  
};

export default CrowdhausPage;
